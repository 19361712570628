import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ThanksPage = () => (
    <Layout>
        <SEO title="Kiitos yhteydenotosta" />
        <section className="section">
            <div className="container">
                <div className="content is-medium">
                    <h1 className="title is-5">Kiitos yhteydenotosta!</h1>
                    <p>Olemme teihin pian yhteydessä.</p>
                </div>
            </div>
        </section>
        <section className="section">
            <div className="container">
                <div class="cta-buttons buttons flex-div">
                    <button className="button is-primary">
                    <Link
                            className="cta"
                            role="button"
                            to="/"
                        >
                            Takaisin etusivulle
                        </Link>
                    </button>
                </div>
            </div>
        </section>
    </Layout >
)

export default ThanksPage
